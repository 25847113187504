import React, { FC, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";

import stripePromise from "../../../common/stripePromise";
import { PaymentType } from "../../../common/types";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";

import Box from "../../Unknown/Box";
import MenuItem from "../../Unknown/MenuItem";
import CircularProgress from "../../Unknown/CircularProgress";
import TextField from "../../Unknown/TextField";
import Button from "../../Unknown/Button";
import { CheckoutAppointment } from "../../Appointment/AppointmentCheckoutScreen/parseCheckoutAppointment";
import PaymentTypeSelection from "../PaymentTypeSelection";
import StripePaymentElement from "../StripePaymentElement";
import usePaymentOptions from "./usePaymentOptions";
import useSubmitHandler from "./useSubmitHandler";
import useTranslations from "./useTranslations";

type CenterUserAppointmentStripePaymentSectionProps = {
  depositAmount?: number;
  totalPrice?: number;
  currencyCode?: string;
  appointment: CheckoutAppointment;
};

const initialPaymentMethodData = {
  paymentMethodId: "",
  centerPaymentMethodId: "",
};

const maxWidth = 362;

const CenterUserAppointmentStripePaymentSection: FC<
  CenterUserAppointmentStripePaymentSectionProps
> = ({
  depositAmount = 0,
  currencyCode = DEFAULT_CURRENCY_CODE,
  totalPrice,
  appointment,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    initialPaymentMethodData,
  );

  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.TotalAmount,
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { appointmentId } = useParams();

  const navigate = useNavigate();

  const { translations } = useTranslations();

  const [searchParams] = useSearchParams();

  const amount = useMemo(() => {
    if (paymentType === "deposit") {
      return depositAmount;
    }

    return totalPrice;
  }, [depositAmount, paymentType, totalPrice]);

  const { onSubmit, isSubmitting } = useSubmitHandler({
    setErrorMessage,
    handleAddPayment: () => {
      navigate(`/${appointmentId}/success`);
    },
  });

  const clientSecret = searchParams.get("clientSecret");

  const handleSelectPaymentType = (newPaymentType: PaymentType) => {
    setSelectedPaymentMethod(initialPaymentMethodData);
    setPaymentType(newPaymentType);
  };

  const [isChangePaymentTypeLoading, setIsChangePaymentTypeLoading] =
    useState(false);

  const [isError, setIsError] = useState(false);

  const { isLoading: isOptionsLoading, paymentOptions } =
    usePaymentOptions(appointment);

  const isLoading = isChangePaymentTypeLoading || isOptionsLoading;

  const handleSubmit = async () => {
    if (!amount) {
      return;
    }
    await onSubmit({ ...selectedPaymentMethod, amount, paymentType });
  };

  const handlePaymentMethodChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedOptionId = target.value;
      const selectedOption = paymentOptions.find(
        (option) => option.id === selectedOptionId,
      );

      if (!selectedOption) return;

      setSelectedPaymentMethod({
        paymentMethodId: selectedOption.id,
        centerPaymentMethodId: selectedOption.centerPaymentMethodId,
      });
    },
    [paymentOptions],
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <Box mb={5}>
        <PaymentTypeSelection
          depositAmount={depositAmount}
          currencyCode={currencyCode}
          paymentType={paymentType}
          appointmentId={appointmentId}
          setIsError={setIsError}
          setIsLoading={setIsChangePaymentTypeLoading}
          setPaymentType={handleSelectPaymentType}
        />
      </Box>
      <Box mt={4} maxWidth={maxWidth}>
        <TextField
          id="payment-method-id"
          label={translations.method}
          select
          required
          fullWidth
          disabled={isSubmitting}
          helperText={errorMessage}
          value={selectedPaymentMethod.paymentMethodId}
          onChange={handlePaymentMethodChange}
        >
          {isOptionsLoading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            paymentOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))
          )}
        </TextField>
      </Box>
      {selectedPaymentMethod.paymentMethodId !== "direct-payment" && (
        <Box mt={4} maxWidth={maxWidth}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              isSubmitting ||
              !selectedPaymentMethod.paymentMethodId ||
              !!errorMessage
            }
            fullWidth
          >
            {translations.confirmPayment}
          </Button>
        </Box>
      )}
      {!!clientSecret &&
        selectedPaymentMethod.paymentMethodId === "direct-payment" && (
          <Box mt={7}>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <StripePaymentElement />
            </Elements>
          </Box>
        )}
    </>
  );
};

export default CenterUserAppointmentStripePaymentSection;
